import React from "react"
import { Link } from "gatsby"
import Layout from "../templates/Layout"
import { Helmet } from "react-helmet"
import * as style from "./404.module.css"

const PageTemplateNotFound = () => {
  // this page is used to containt he modal form fields required by netlify to process submissions.
  return (
    <>
      <Helmet>
        <title>page not found | waracle</title>
        <meta name="robots" content="nofollow, noindex" />
      </Helmet>

      <Layout>
        <video
          playsInline
          autoPlay
          muted
          loop
          poster=""
          style={{
            objectFit: "cover",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
          }}
        >
          <source
            src="https://res.cloudinary.com/dvmdv4ttu/video/upload/assets/dev/clouds_video_kfzk1q.mp4"
            type="video/mp4"
          />
        </video>

        <div
          className={`z_index_top d-flex align-items-center position-relative ${style.custom_heights}`}
        >
          <div className="container">
            <div id="hide-row-when-nav-open" className="row">
              <div className="col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start color-white z_index_top home-header animated-headline pe-lg-5">
                <div className="animated-headline-wrap">
                  <h1 className={`${style.error_404} mb-5 header-fade-in`}>
                    Mmmm..{" "}
                  </h1>
                  <h2 className="new_style">
                    Every cloud has a silver lining but... We've lost you!
                    Return <Link to="/">home</Link>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="dark_image_overly"></div>
        </div>

        {/*hidden forms for applications and mailchimp modals, required by netlify*/}

        <div style={{ display: "none" }}>
          <form
            name="recruitmentApplication"
            data-netlify="true"
            netlify-honeypot="enqMiscData"
            data-netlify-recaptcha="true"
          >
            <input
              type="hidden"
              name="form-name"
              value="recruitmentApplication"
            />
            <input type="hidden" name="enqPortfolio" />
            <input type="hidden" name="enqFirstName" />
            <input type="hidden" name="enqSecondName" />
            <input type="hidden" name="enqSalary" />
            <input type="hidden" name="enqEmail" />
            <input type="hidden" name="enqTelephone" />
            <input type="hidden" name="enqMessage" />
            <input type="hidden" name="enqRecruiteeId" />
            <input type="hidden" name="enqCV" />
            <input type="hidden" name="enqMiscData" />
          </form>

          <form
            name="newsletterRecruitment"
            data-netlify="true"
            netlify-honeypot="enqMiscData"
            data-netlify-recaptcha="true"
          >
            <input type="hidden" name="enqEmail" />
            <input
              type="hidden"
              name="form-name"
              value="newsletterRecruitment"
            />
            <input type="hidden" name="enqMiscData" />
          </form>

          <form
            name="newsletterEvents"
            data-netlify="true"
            netlify-honeypot="enqMiscData"
            data-netlify-recaptcha="true"
          >
            <input type="hidden" name="enqEmail" />
            <input type="hidden" name="form-name" value="newsletterEvents" />
            <input type="hidden" name="enqMiscData" />
          </form>

          <form
            name="vrDemoApplication"
            data-netlify="true"
            netlify-honeypot="enqMiscData"
            data-netlify-recaptcha="true"
          >
            <input type="hidden" name="form-name" value="vrDemoApplication" />
            <input type="hidden" name="enqName" />
            <input type="hidden" name="enqEmail" />
            <input type="hidden" name="enqCompany" />
            <input type="hidden" name="enqTitle" />
            <input type="hidden" name="enqMiscData" />
          </form>

          <form
            name="eventRegistration"
            data-netlify="true"
            netlify-honeypot="enqMiscData"
            data-netlify-recaptcha="true"
          >
            <input type="hidden" name="form-name" value="eventRegistration" />
            <input type="hidden" name="enqFirstName" />
            <input type="hidden" name="enqSecondName" />
            <input type="hidden" name="enqEmail" />
            <input type="hidden" name="enqCompany" />
            <input type="hidden" name="enqJobtitle" />
            <input type="hidden" name="enqMiscData" />
          </form>
        </div>
      </Layout>
    </>
  )
}

export default PageTemplateNotFound
